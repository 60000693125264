// TODO: CONVERT TO TYPESCRIPT
import React from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import TextInput from 'components/fields/TextInput'
import { api } from 'components/api'
import { setUserPasswordPath } from 'components/routes/api'
import HeaderIcon from 'images/authentication_modals/sign-in.svg'
import ContactSupport from './ContactSupport'

function SetPassword (props) {
  const { email, onSuccess, supportEmail } = props

  return (
    <Formik
      initialValues={{ password: '', password_confirmation: '' }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters')
          .max(16, 'Password has maximum length of 16 characters'),
        password_confirmation: Yup.string()
          .required('Password Confirmation is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
      })}
      onSubmit={(values, { setFieldError }) => {
        api.patchWithCsrf({
          url: setUserPasswordPath(),
          data: {
            user: {
              password: values.password,
              password_confirmation: values.password_confirmation,
              email
            }
          }
        })
          .then((response) => {
            if (response.data.status === 'success') {
              onSuccess()
            } else if (response.data.errors.user) {
              if (response.data.errors.user.password) {
                setFieldError('password_confirmation', response.data.errors.user.password)
              }
            }
          })
      }}
    >
      <Form>
        <div className='modal-top'>
          <img src={HeaderIcon} className='header-icon' alt='header icon' />
          <div className='header pro-head-5' role='heading'>Set Password</div>
          <p className='message'>
            Finish up by setting a password for <b>{email}</b>. It must be at least 6 characters long.
          </p>
        </div>
        <div className='modal-bottom'>

          <TextInput
            type='password'
            label='New Password'
            name='password'
            placeholder='Enter Password'
            autoFocus
            autoComplete='off'
          />

          <TextInput
            type='password'
            label='Retype New Password'
            name='password_confirmation'
            placeholder='Re-enter Password'
            autoComplete='off'
          />

          <div className='grid-x align-middle buttons'>
            <div className='cell'>
              <button type='submit' className='button submit-btn float-right'>SUBMIT</button>
            </div>
          </div>

          <ContactSupport supportEmail={supportEmail} />
        </div>
      </Form>
    </Formik>
  )
}

SetPassword.propTypes = {
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  supportEmail: PropTypes.string.isRequired
}

export default SetPassword
