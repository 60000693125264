// TODO: CONVERT TO TYPESCRIPT
import React from 'react'
import PropTypes from 'prop-types'

class UserConfirmationModal extends React.Component {
  constructor (props) {
    super(props)

    this.$modal = $(`#${props.modalId}`)
    this.state = {
      pagesVisited: [],
      currentPage: this.props.initialPage,
      isRevealed: false
    }
  }

  componentDidMount () {
    this.$modal.on('open.zf.reveal', () => {
      this.setState({ isRevealed: true })
    })

    this.$modal.on('closed.zf.reveal', () => {
      if (this.props.onModalClose) {
        this.props.onModalClose(this.close)
      }
    })

    if (window.location.hash === '#confirm_user') {
      this.$modal.foundation()
      this.open()
    }
  }

  open = () => {
    this.$modal.foundation('open')
    this.setState({ isRevealed: true })
  }

  close = () => {
    this.setState({
      currentPage: this.props.initialPage,
      pagesVisited: [],
      isRevealed: false
    })

    this.$modal.foundation('close')
  }

  advanceToPage = (page) => {
    this.setState((prevState) => {
      return {
        pagesVisited: [...prevState.pagesVisited, prevState.currentPage],
        currentPage: page
      }
    })
  }

  goBackToPreviousPage = () => {
    if (this.state.pagesVisited.length === 0) {
      return
    }

    this.setState(({ pagesVisited }) => {
      return {
        currentPage: pagesVisited[pagesVisited.length - 1],
        pagesVisited: pagesVisited.slice(0, -1)
      }
    })
  }

  render () {
    return this.props.render({
      currentPage: this.state.currentPage,
      isRevealed: this.state.isRevealed,
      advanceToPage: this.advanceToPage,
      goBackToPreviousPage: this.goBackToPreviousPage,
      close: this.close
    })
  }
}

UserConfirmationModal.propTypes = {
  render: PropTypes.func.isRequired,
  initialPage: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired
}

UserConfirmationModal.defaultProps = {
  onModalClose: () => {
  }
}

export default UserConfirmationModal
