import React from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { api } from 'components/api'
import { createSessionPath } from 'components/routes/api'
import * as Yup from 'yup'
import HeaderIcon from 'images/authentication_modals/sign-in.svg'
import TextInput from 'components/fields/TextInput'

function SignIn (props) {
  const resetPasswordLink = () => (
    <a className='float-right forgot-password' onClick={props.onResetPassword}>Forgot Password?</a>
  )

  const { email } = props

  return (
    <Formik
      initialValues={{ email, password: '' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
        password: Yup.string().required('Password is required')
      })}
      onSubmit={(values, { setFieldError }) => {
        api.postWithCsrf({
          url: createSessionPath(),
          data: { email: values.email, password: values.password }
        }).then((response) => {
          if (response.data.status === 'success') {
            props.onSuccess(response.data)
          } else {
            setFieldError('password', 'Incorrect email/password')
          }
        })
      }}
    >
      <Form>
        <div className='modal-top'>
          <img src={HeaderIcon} className='header-icon' />
          <div className='header pro-head-5' role='heading'>Sign in. There is already an account with this Email.</div>
        </div>
        <div className='modal-bottom'>
          <TextInput
            id='email'
            type='email'
            label='Email'
            name='email'
            placeholder='Enter Email'
          />

          <TextInput
            id='password'
            type='password'
            label='Password'
            labelLink={resetPasswordLink}
            name='password'
            placeholder='Enter Password'
          />

          <div className='grid-x align-middle buttons'>
            <div className='cell small-6'>
              {props.onGoBack &&
                <a onClick={props.onGoBack} className='go-back-btn'>Go Back</a>}
            </div>
            <div className='cell small-6'>
              <button type='submit' className='button submit-btn float-right'>SIGN IN</button>
            </div>
          </div>

          <div className='clearfix' />
        </div>
      </Form>
    </Formik>
  )
}

SignIn.propTypes = {
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onGoBack: PropTypes.func
}

export default SignIn
