import React from 'react'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { createSessionPath } from 'components/routes/api'
import { api } from 'components/api'
import * as Yup from 'yup'
import TextInput from 'components/fields/TextInput'
import HeaderIcon from 'images/authentication_modals/sign-in.svg'

function EnterPassword (props) {
  const resetPasswordLink = () => (
    <a id='reset-password' className='float-right' onClick={props.onResetPassword}>Reset Password</a>
  )

  const { email } = props

  return (
    <Formik
      initialValues={{ email, password: '' }}
      validationSchema={Yup.object({
        password: Yup.string().required('Password is required')
      })}
      onSubmit={(values, { setFieldError }) => {
        api.postWithCsrf({
          url: createSessionPath(),
          data: {
            email: values.email,
            password: values.password
          }
        }).then((response) => {
          if (response.data.status === 'success') {
            props.onSuccess(response.data)
          } else {
            setFieldError('password', 'Incorrect email/password')
          }
        })
      }}
    >
      <Form>
        <div className='modal-top'>
          <img src={HeaderIcon} alt='Icon' className='header-icon' />
          <div className='header pro-head-5' role='heading'>Enter Password</div>
          {props.message ||
            <p className='message'>Enter the password for your <b>{props.email}</b> account.</p>}
        </div>
        <div className='modal-bottom'>
          <TextInput
            id='password'
            type='password'
            label='Password'
            labelLink={resetPasswordLink}
            name='password'
            placeholder='Enter Password'
          />

          <div className='grid-x align-middle buttons'>
            <div className='cell small-6'>
              {props.onGoBack &&
                <a onClick={props.onGoBack} className='go-back-btn underline'>Go Back</a>}
            </div>
            <div className='cell small-6'>
              <button type='submit' className='button submit-btn float-right'>SUBMIT</button>
            </div>
          </div>

          <div className='clearfix' />
        </div>
      </Form>
    </Formik>
  )
}

EnterPassword.propTypes = {
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onGoBack: PropTypes.func
}

export default EnterPassword
