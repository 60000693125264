import React from 'react'
import PropTypes from 'prop-types'

export interface LoadingDotsProps {
  show: boolean
}

const LoadingDots = ({ show }: LoadingDotsProps): JSX.Element => {
  return (
    <div className={`loading-dots ${show ? '' : 'hide'}`}>
      <span>&nbsp;&#8226;&nbsp;</span>
      <span>&nbsp;&#8226;&nbsp;</span>
      <span>&nbsp;&#8226;&nbsp;</span>
      <span>&nbsp;&#8226;&nbsp;</span>
    </div>
  )
}

LoadingDots.propTypes = {
  show: PropTypes.bool.isRequired
}

export default LoadingDots
