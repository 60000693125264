import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextInput from 'components/fields/TextInput'
import { userStatusPath } from 'components/routes/api'
import { api } from 'components/api'
import HeaderIcon from 'images/authentication_modals/email.svg'

class EnterEmail extends React.Component {
  constructor (props) {
    super(props)
    this.submitRef = React.createRef()
  }

  render () {
    const { email, onSuccess } = this.props

    return (
      <Formik
        initialValues={{ email }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
        })}
        onSubmit={(values) => {
          api.get({ url: userStatusPath(), params: { email: values.email } })
            .then((response) => {
              onSuccess({
                email: values.email,
                userStatus: response.data.user.status,
                userType: response.data.user.type,
                userId: response.data.user.id,
                setPassword: response.data.user.set_password
              })
            })
        }}
      >
        <Form>
          <div className='modal-top'>
            <img src={HeaderIcon} alt='Enter Email' className='header-icon' />
            <div className='header pro-head-5' role='heading'>
              Enter Email
            </div>
            {this.props.loginFormMessage}
          </div>
          <div className='modal-bottom'>

            <TextInput
              type='email'
              label='Work Email'
              name='email'
              placeholder='name@example.com'
            />

            <button type='submit' className='button float-right next'>NEXT</button>

            <div className='clearfix' />

            <hr />

            <div className='text-center'>
              New Here? <a id='create-account' onClick={this.props.onCreateAccount} className='underline'>Create an Account</a>
            </div>

          </div>
        </Form>
      </Formik>
    )
  }
}

EnterEmail.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  loginFormMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  createAccountMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  email: PropTypes.string,
  onCreateAccount: PropTypes.func.isRequired,
  supportEmail: PropTypes.string.isRequired
}

EnterEmail.defaultProps = {
  email: ''
}

export default EnterEmail
