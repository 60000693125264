// TODO: CONVERT TO TYPESCRIPT
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextInput from '../../fields/TextInput'
import HeaderIcon from 'images/authentication_modals/sign-up.svg'

function SignUpPage1 (props) {
  const { name, email, emailDisabled, subHeaderMessage, onGoBack, onSuccess } = props

  return (
    <Formik
      initialValues={{
        name,
        email
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Name is required'),
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required')
      })}
      onSubmit={(values) => {
        onSuccess(values)
      }}
    >
      <Form autoComplete='off'>
        <div className='modal-top'>
          <img src={HeaderIcon} className='header-icon' />
          <div className='header pro-head-5' role='heading'>Contact Information</div>
          <p className='message'>
            Procurated is a private platform of procurement professionals from governments, education institutions, and non-profits, across the country.
          </p>

          {subHeaderMessage}
        </div>
        <div className='modal-bottom'>
          <TextInput
            type='text'
            label='Name'
            name='name'
            placeholder='John Smith'
          />

          <TextInput
            type='text'
            label='Work Email'
            name='email'
            disabled={emailDisabled}
            placeholder='name@example.com'
          />

          <div className='grid-x align-middle buttons'>
            <div className='cell small-6'>
              {onGoBack &&
                <a onClick={onGoBack} className='go-back-btn'>Go Back</a>}
            </div>
            <div className='cell small-6'>
              <button type='submit' className='button submit-btn float-right'>NEXT</button>
            </div>
          </div>

        </div>
      </Form>
    </Formik>
  )
}

SignUpPage1.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  emailDisabled: PropTypes.bool.isRequired,
  subHeaderMessage: PropTypes.object.isRequired,
  onGoBack: PropTypes.func
}

SignUpPage1.defaultProps = {
  name: ''
}

export default SignUpPage1
