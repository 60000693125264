import React from 'react'
import PropTypes from 'prop-types'

export interface InvalidEmailProps {
  headerMessage: React.ReactNode
  subHeaderMessage: React.ReactNode
  supportEmail: string
}

function InvalidEmail ({ headerMessage, subHeaderMessage, supportEmail }: InvalidEmailProps): JSX.Element {
  return (
    <>
      <div className='modal-top'>
        <div className='header pro-head-5' role='heading'>{headerMessage}</div>
        <p className='message'>{subHeaderMessage}</p>

        <hr />

        <p className='text-center'>
          To inquire about this, <a href={`mailto:${supportEmail}`} className='underline'>Contact Support</a>
        </p>
      </div>
    </>
  )
}

InvalidEmail.propTypes = {
  headerMessage: PropTypes.string.isRequired,
  subHeaderMessage: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired
}

export default InvalidEmail
