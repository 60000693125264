// TODO: CONVERT TO TYPESCRIPT
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'
import { api } from 'components/api'
import HeaderIcon from 'images/authentication_modals/sign-in.svg'
import TextInput from 'components/fields/TextInput'
import { userConfirmWithAssistancePath } from 'components/routes/api'

const formSchema = Yup.object({
  email: Yup.string()
    .email('Email is invalid')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
})

function VerifyUser (props) {
  const onSubmit = (values, { setFieldError }) => {
    api.patchWithCsrf({
      url: userConfirmWithAssistancePath(),
      data: {
        email: props.email,
        admin_email: values.email,
        admin_password: values.password
      }
    })
      .then((response) => {
        if (response.data.status === 'success') {
          props.onSuccess()
        } else {
          setFieldError('password', 'Invalid email and/or password')
        }
      })
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className='modal-top'>
          <img src={HeaderIcon} className='header-icon' />
          <div className='header pro-head-5' role='heading'>Verify User</div>
          <p className='message'><b>Name:</b> {props.name}</p>
          <p className='message'><b>Email:</b> {props.email}</p>
          <p className='message'><b>Agency:</b> {props.organizationName}</p>
          <p className='message'><b>State:</b> {props.stateName}</p>
        </div>
        <div className='modal-bottom'>
          <TextInput name='email' type='email' label='Email' placeholder='name@example.com' />
          <TextInput name='password' type='password' label='Password' placeholder='Enter Password' />

          <div className='grid-x align-middle buttons'>
            <div className='cell small-6'>
              <a onClick={props.onGoBack} className='go-back-btn'>Go Back</a>
            </div>
            <div className='cell small-6'>
              <button type='submit' className='button submit-btn float-right'>APPROVE</button>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

VerifyUser.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  stateName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired
}

export default VerifyUser
