import React from 'react'
import PropTypes from 'prop-types'

export interface ContactSupportProps {
  supportEmail: string
}

const ContactSupport: React.FC<ContactSupportProps> = (props) => {
  return (
    <div className='contact-support text-center'>
      <hr />
      Having trouble? <a href={`mailto:${props.supportEmail}`}>Contact Support</a>
    </div>
  )
}

ContactSupport.propTypes = {
  supportEmail: PropTypes.string.isRequired
}

export default ContactSupport
