import React from 'react'
import PropTypes from 'prop-types'
import SignUpIcon from 'images/authentication_modals/sign-up.svg'
import AgencyOnIcon from 'images/authentication_modals/agency-on.svg'
import AgencyOffIcon from 'images/authentication_modals/agency-off.svg'
import SupplierOnIcon from 'images/authentication_modals/supplier-on.svg'
import SupplierOffIcon from 'images/authentication_modals/supplier-off.svg'
import ArrowIcon from 'images/authentication_modals/arrow.svg'
import { claimYourPagePath } from '../../routes/path'

interface ChooseUserTypeProps {
  onSuccess: (page: string) => void
  onSignIn: () => void
}

const ChooseUserType: React.FC<ChooseUserTypeProps> = (props) => {
  const handleAgencyClick = (): void => {
    props.onSuccess('SignUpPage1')
  }

  const handleSupplierClick = (): void => {
    window.location.replace(claimYourPagePath())
  }

  return (
    <>
      <div className='modal-top' data-testid='initial-signup-modal'>
        <img src={SignUpIcon} className='header-icon' alt='sign up' />
        <div className='header pro-head-5' role='heading'>
          Sign up
        </div>

        <p className='message'>
          Are you a buyer or supplier? Choose one.
        </p>
      </div>
      <div className='modal-bottom'>
        <div id='agency-btn' className='user-type-btn' onClick={handleAgencyClick}>
          <img src={AgencyOnIcon} className='icon icon-on' alt='buyer selected' />
          <img src={AgencyOffIcon} className='icon icon-off' alt='buyer unselected' />
          I am a Buyer

          <img src={ArrowIcon} alt='arrow' className='arrow' />
        </div>
        <div id='supplier-btn' className='user-type-btn' onClick={handleSupplierClick}>
          <img src={SupplierOnIcon} className='icon icon-on' alt='supplier selected' />
          <img src={SupplierOffIcon} className='icon icon-off' alt='supplier unselected' />
          I am a Supplier
          <img src={ArrowIcon} alt='arrow' className='arrow' />
        </div>
        <div className='sign-in-message text-center'>
          <hr />
          Already a Procurated member? <a onClick={props.onSignIn} className='underline'>Sign-in</a>
        </div>
      </div>
    </>
  )
}

ChooseUserType.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired
}

export default ChooseUserType
