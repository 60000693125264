import React from 'react'
import PropTypes from 'prop-types'
import ChooseUserType from './pages/ChooseUserType'
import SignUpPage1 from './pages/SignUpPage1'
import SignUpPage2 from './pages/SignUpPage2'
import UserConfirmationModal from './UserConfirmationModal'
import { api } from 'components/api'
import { userStatusPath } from '../routes/api'
import SignIn from './pages/SignIn'
import EnterCode from './pages/EnterCode'
import SetPassword from './pages/SetPassword'
import InvalidEmail from './pages/InvalidEmail'
import PendingApproval from './pages/PendingApproval'
import VerifyUser from './pages/VerifyUser'

class SignUpModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = this.initialState()
  }

  initialState = () => ({
    email: '',
    name: '',
    confirmed: false
  })

  handleModalClose = (close) => {
    if (!this.state.confirmed) {
      this.setState(this.initialState())
      close()
    }
  }

  renderPage = ({
    currentPage,
    advanceToPage,
    goBackToPreviousPage,
    close
  }) => {
    const onVerificationSuccess = () => {
      this.setState({ confirmed: true })
      advanceToPage('SetPassword')
    }

    const advanceToVerifyUser = () => {
      api.get({
        url: userStatusPath(),
        params: { email: this.state.email }
      })
        .then((response) => {
          this.setState({
            organizationName: response.data.user.organization_name,
            stateName: response.data.user.state_name
          })
          advanceToPage('VerifyUser')
        })
    }

    switch (currentPage) {
      case 'ChooseUserType':
        return (
          <ChooseUserType
            onSuccess={() => {
              advanceToPage('SignUpPage1')
            }}
            onSignIn={() => {
              close()
              $(`#${this.props.signInModalId}`).foundation('open')
            }}
          />
        )
      case 'SignUpPage1':
        return (
          <SignUpPage1
            name={this.state.name}
            email={this.state.email}
            emailDisabled={false}
            subHeaderMessage={
              <p className='message'>
                Submit your information to request access.
              </p>
          }
            onGoBack={goBackToPreviousPage}
            onSuccess={(data) => {
              this.setState(data)
              api.get({
                url: userStatusPath(),
                params: { email: data.email }
              })
                .then((response) => {
                  switch (response.data.user.status) {
                    case 'verified':
                    case 'self_registered':
                      advanceToPage('SignIn')
                      break
                    case 'approved':
                    case 'preloaded':
                    case 'phonerep_added':
                      advanceToPage('EnterCode')
                      break
                    case 'rejected':
                    case 'disabled':
                      advanceToPage('InvalidEmail')
                      break
                    case 'supplier_self_registered':
                      advanceToPage('PendingApproval')
                      break
                    default:
                      advanceToPage('SignUpPage2')
                      break
                  }
                })
            }}
          />
        )
      case 'SignUpPage2':
        return (
          <SignUpPage2
            email={this.state.email}
            name={this.state.name}
            subHeaderMessage={
              <>
                <p className='message'>
                  Procurated is a private platform of procurement professionals
                  from governments, education institutions, and non-profits,
                  across the country.
                </p>
                <p className='message'>
                  Submit your information to request access.
                </p>
              </>
          }
            onGoBack={goBackToPreviousPage}
            onSuccess={() => {
              advanceToPage('EnterCode')
            }}
          />
        )
      case 'SignIn':
        return (
          <SignIn
            email={this.state.email}
            onSuccess={() => {
              const redirectTo = localStorage.getItem('redirect_to')
              localStorage.removeItem('redirect_to')
              if (redirectTo) {
                window.location.href = redirectTo
              } else {
                window.location.reload()
              }
            }}
            onResetPassword={() => advanceToPage('EnterCode')}
          />
        )
      case 'EnterCode':
        return (
          <EnterCode
            email={this.state.email}
            supportEmail={this.props.supportEmail}
            readyToSendInitialCode
            onSuccess={onVerificationSuccess}
            onAdvanceToVerifyUser={advanceToVerifyUser}
          />
        )
      case 'VerifyUser':
        return (
          <VerifyUser
            name={this.state.name}
            email={this.state.email}
            organizationName={this.state.organizationName}
            stateName={this.state.stateName}
            onSuccess={onVerificationSuccess}
            onGoBack={goBackToPreviousPage}
          />
        )
      case 'SetPassword':
        return (
          <SetPassword
            email={this.state.email}
            supportEmail={this.props.supportEmail}
            onSuccess={() => { window.location.href = '/' }}
          />
        )
      case 'InvalidEmail':
        return (
          <InvalidEmail
            headerMessage='Access Denied'
            supportEmail={this.props.supportEmail}
            subHeaderMessage={
              <>
                Procurated is a private community of public procurement
                professionals and the suppliers who serve them. Please{' '}
                <a href={`mailto:${this.props.supportEmail}`}>
                  contact support
                </a>{' '}
                if you believe you have received this message in error.
              </>
          }
          />
        )
      case 'PendingApproval':
        return <PendingApproval />
    }
  }

  render () {
    return (
      <UserConfirmationModal
        render={this.renderPage}
        initialPage='ChooseUserType'
        modalId={this.props.modalId}
        onModalClose={this.handleModalClose}
      />
    )
  }
}

SignUpModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired
}

export default SignUpModal
