// TODO: CONVERT TO TYPESCRIPT
import React from 'react'
import HeaderIcon from 'images/authentication_modals/sign-up.svg'

function PendingApproval () {
  return (
    <div className='modal-top'>
      <img src={HeaderIcon} alt='header icon' className='header-icon' />
      <div className='header pro-head-5' role='heading'>Pending Approval</div>
      <p className='message'>
        We will notify you via email when you have been approved.
      </p>
    </div>
  )
}

export default PendingApproval
