// TODO: CONVERT TO TYPESCRIPT
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { api } from 'components/api'
import SelectAgency from 'components/fields/SelectAgency'
import SelectState from 'components/fields/SelectState'
import { createUserPath } from 'components/routes/api'
import HeaderIcon from 'images/authentication_modals/sign-up.svg'

class SignUpPage2 extends React.Component {
  constructor (props) {
    super(props)
    this.submitRef = React.createRef()

    this.state = {
      isSubmitted: false,
      states: []
    }
  }

  handleKeyDown = (event) => {
    // prevent enter from submitting the form
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault()
    }
  }

  render () {
    const { name, email, subHeaderMessage, onSuccess, onGoBack } = this.props

    return (
      <Formik
        initialValues={{
          name,
          email,
          state: '',
          organization: '',
          organization_id: '',
          organization_name: ''
        }}
        validationSchema={Yup.object({
          state: Yup.string()
            .required('State is required'),
          organization_name: Yup.string()
            .required('Organization is required')
        })}
        onSubmit={(values, { setFieldError }) => {
          this.setState({ isSubmitting: true })
          api.postWithCsrf({
            url: createUserPath(),
            data: {
              user: {
                name: values.name,
                email: values.email,
                state: values.state,
                organization_name: values.organization_name,
                organization_id: values.organization_id,
                signup_source: `Signup Modal (${window.location.pathname})`
              }
            }
          }
          ).then((response) => {
            if (response.data.status === 'success') {
              onSuccess({ userId: response.data.user.id })
            } else {
              for (const [key, errorMessage] of Object.entries(response.data.errors.user)) {
                setFieldError(key, errorMessage)
                this.setState({ isSubmitting: false })
              }
            }
          })
        }}
      >
        {({ values, handleChange }) => (
          <Form autoComplete='off' onKeyDown={this.handleKeyDown}>
            <div className='modal-top'>
              <img src={HeaderIcon} className='header-icon' />
              <div className='header pro-head-5' role='heading'>Contact Information</div>
              {subHeaderMessage}
            </div>
            <div className='modal-bottom'>
              <SelectState />
              <SelectAgency
                type='text'
                label='Organization Name'
                name='organization'
                placeholder='Eg: Department of Energy'
                stateCode={values.state}
                onChange={handleChange}
              />

              <div className='grid-x align-middle buttons'>
                <div className='cell small-6'>
                  {onGoBack &&
                    <a onClick={onGoBack} className='go-back-btn'>Go Back</a>}
                </div>
                <div className='cell small-6'>
                  <button type='submit' disabled={this.state.isSubmitting} className='button submit-btn float-right'>NEXT</button>
                </div>
              </div>

            </div>
          </Form>
        )}
      </Formik>
    )
  }
}

SignUpPage2.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subHeaderMessage: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onGoBack: PropTypes.func
}

export default SignUpPage2
