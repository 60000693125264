// TODO: CONVERT TO TYPESCRIPT
import React from 'react'
import PropTypes from 'prop-types'
import HeaderIcon from 'images/authentication_modals/sign-up.svg'

class EmailNotFound extends React.Component {
  render () {
    const { email, onGoBack, onSignUp } = this.props

    return (
      <>
        <div className='modal-top'>
          <img src={HeaderIcon} alt='header icon' className='header-icon' />
          <div className='header pro-head-5' role='heading'>Email does not exist</div>
          <p className='message'>
            We are unable to find an account associated with <b>{email}</b>. If you have not signed up, please click Sign Up to continue
          </p>
        </div>
        <div className='modal-bottom'>
          <div className='grid-x align-middle buttons'>
            <div className='cell small-6'>
              <a onClick={onGoBack} className='go-back-btn'>Go Back</a>
            </div>
            <div className='cell small-6'>
              <button onClick={onSignUp} className='button submit-btn float-right'>SIGN UP</button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

EmailNotFound.propTypes = {
  email: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired
}

export default EmailNotFound
