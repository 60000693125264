import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { api } from 'components/api'
import { sendUserAuthenticationPin, userConfirmationPath } from 'components/routes/api'
import TextInput from 'components/fields/TextInput'
import ContactSupport from './ContactSupport'
import HeaderIcon from 'images/authentication_modals/sign-in.svg'

const sendVerificationCode = (email) => {
  return api.postWithCsrf({
    url: sendUserAuthenticationPin(),
    params: {
      email
    }
  })
}

const confirmVerificationCode = (params) => {
  return api.patchWithCsrf({
    url: userConfirmationPath(),
    data: {
      email: params.email,
      verification_code: params.verificationCode
    }
  })
}

function EnterCode (props) {
  const [initialCodeSent, setInitialCodeSent] = useState(false)
  const [showResendCode, setShowResendCode] = useState(true)

  useEffect(() => {
    if (props.readyToSendInitialCode && !initialCodeSent) {
      props.sendVerificationCode(props.email)
      setInitialCodeSent(true)
    }
  }, [props.readyToSendInitialCode, initialCodeSent])

  const resendCode = () => {
    setShowResendCode(false)

    props.sendVerificationCode(props.email)

    setTimeout(() => {
      setShowResendCode(true)
    }, props.resendCodeTimeout)
  }

  const resendCodeLink = showResendCode
    ? (
      <a onClick={resendCode}>Resend code</a>
      )
    : (
      <span className='code-sent'>Code Sent</span>
      )

  const formHeader = (
    <>
      <img src={HeaderIcon} className='header-icon' />
      <div className='header pro-head-5' role='heading'>Enter Code</div>
      {props.message ||
        <p className='message'>
          We emailed a code to your email address <b>{props.email}</b>. Please enter the code below.
        </p>}
      <p className='message'>
        The code will expire in 30 minutes.
      </p>
    </>
  )

  const formSchema = Yup.object({
    verification: Yup.string()
      .required('Required')
      .min(4, 'Must be 4 characters')
      .max(4, 'Must be 4 characters')
  })

  const handleSubmission = (values, { setFieldError }) => {
    props.confirmVerificationCode({
      email: props.email,
      verificationCode: values.verification
    }).then((response) => {
      if (response.data.status === 'success') {
        props.onSuccess()
      } else {
        setFieldError('verification', 'Incorrect Code, Try Again')
      }
    })
  }

  return (
    <Formik
      initialValues={{ verification: '' }}
      validationSchema={formSchema}
      onSubmit={handleSubmission}
    >
      {({ values }) => (
        <Form>
          <div className='modal-top'>
            {formHeader}
          </div>
          <div className='modal-bottom'>
            <TextInput
              label='Enter 4 digit code'
              name='verification'
              className='verification-code-input'
              maxLength='4'
              showErrorHighlight
            />

            <div className='grid-x align-middle buttons'>
              <div className='cell small-6'>
                {resendCodeLink}
              </div>
              <div className='cell small-6'>
                <button
                  type='submit'
                  className='button submit-btn float-right'
                  disabled={values.verification.length !== 4}
                >
                  VERIFY
                </button>
              </div>
            </div>

            <ContactSupport supportEmail={props.supportEmail} />
            <AdvanceToVerifyUser onClick={props.onAdvanceToVerifyUser} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

EnterCode.propTypes = {
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  readyToSendInitialCode: PropTypes.bool,
  supportEmail: PropTypes.string.isRequired,
  sendVerificationCode: PropTypes.func,
  confirmVerificationCode: PropTypes.func,
  resendCodeTimeout: PropTypes.number,
  onAdvanceToVerifyUser: PropTypes.func
}

EnterCode.defaultProps = {
  readyToSendInitialCode: true,
  sendVerificationCode,
  confirmVerificationCode,
  resendCodeTimeout: 4000
}

function AdvanceToVerifyUser (props) {
  return (
    <div className='advance-to-verify-user text-center'>
      <a onClick={props.onClick}>
        At a conference and need help?<br />
        Go to Procurated's booth for help.
      </a>
    </div>
  )
}

AdvanceToVerifyUser.propTypes = {
  onClick: PropTypes.func
}

export default EnterCode
