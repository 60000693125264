import React from 'react'
import PropTypes from 'prop-types'
import UserConfirmationModal from './UserConfirmationModal'
import EnterCode from './pages/EnterCode'
import SetPassword from './pages/SetPassword'
import EnterEmail from './pages/EnterEmail'
import InvalidEmail from './pages/InvalidEmail'
import EnterPassword from './pages/EnterPassword'
import EmailNotFound from './pages/EmailNotFound'
import PendingApproval from './pages/PendingApproval'
import { api } from 'components/api'
import { userStatusPath } from '../routes/api'
import VerifyUser from './pages/VerifyUser'

class SignInModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = this.initialState()
  }

  initialState = () => (
    {
      email: '',
      confirmed: false
    }
  )

  handleModalClose = close => {
    if (!this.state.confirmed) {
      this.setState(this.initialState())
      close()
    }
  }

  pageAfterEnterEmail = ({ userStatus, setPassword }) => {
    let nextPage
    if (['rejected', 'disabled'].includes(userStatus)) {
      nextPage = 'InvalidEmail'
    } else if (['verified', 'self_registered'].includes(userStatus)) {
      if (setPassword) {
        nextPage = 'SetPassword'
      } else {
        nextPage = 'EnterPassword'
      }
    } else if (['approved', 'phonerep_added', 'preloaded'].includes(userStatus)) {
      nextPage = 'EnterCode'
    } else if (userStatus === 'supplier_self_registered') {
      nextPage = 'PendingApproval'
    } else {
      nextPage = 'EmailNotFound'
    }

    return nextPage
  }

  renderPage = ({ currentPage, advanceToPage, goBackToPreviousPage, close }) => {
    const onVerificationSuccess = () => {
      this.setState({ confirmed: true })
      advanceToPage('SetPassword')
    }

    const advanceToVerifyUser = () => {
      api.get({
        url: userStatusPath(),
        params: { email: this.state.email }
      })
        .then((response) => {
          let nextPage = 'EmailNotFound'
          if (response?.data?.status === 'success') {
            this.setState({
              name: response.data.user.name,
              organizationName: response.data.user.organization_name,
              stateName: response.data.user.state_name
            })
            nextPage = 'VerifyUser'
          }
          advanceToPage(nextPage)
        })
    }

    switch (currentPage) {
      case 'EnterEmail':
        return (
          <EnterEmail
            email={this.state.email}
            supportEmail={this.props.supportEmail}
            onSuccess={(data) => {
              this.setState(data)
              advanceToPage(this.pageAfterEnterEmail(data))
            }}
            onCreateAccount={() => {
              close()
              $('#sign-up-modal').foundation('open')
            }}
          />
        )
      case 'InvalidEmail':
        return (
          <InvalidEmail
            headerMessage='Access Denied'
            supportEmail={this.props.supportEmail}
            subHeaderMessage={
              <>
                Procurated is a private community of public procurement professionals and the suppliers who serve them.
                Please <a href={`mailto:${this.props.supportEmail}`}>contact support</a> if you believe you have received this message in error.
              </>
        }
          />
        )
      case 'EnterPassword':
        return (
          <EnterPassword
            email={this.state.email}
            message={
              <>
                <p className='message'>One last step!</p>
                <p className='message'>Enter the password for your <b>{this.state.email}</b> account.</p>
              </>
        }
            onGoBack={goBackToPreviousPage}
            onSuccess={(data) => {
              const redirectTo = localStorage.getItem('redirect_to')
              localStorage.removeItem('redirect_to')
              if (redirectTo) {
                window.location.href = redirectTo
              } else {
                window.location.href = data.redirect_link || ''
              }
            }}
            onResetPassword={() => advanceToPage('EnterCode')}
          />
        )
      case 'EnterCode':
        return (
          <EnterCode
            email={this.state.email}
            supportEmail={this.props.supportEmail}
            message={
              <p className='message'>
                We emailed a code to your email address <b>{this.state.email}</b>. Please enter the code to continue.
              </p>
        }
            onSuccess={onVerificationSuccess}
            onAdvanceToVerifyUser={advanceToVerifyUser}
          />
        )
      case 'VerifyUser':
        return (
          <VerifyUser
            name={this.state.name}
            email={this.state.email}
            organizationName={this.state.organizationName}
            stateName={this.state.stateName}
            onSuccess={onVerificationSuccess}
            onGoBack={goBackToPreviousPage}
          />
        )
      case 'SetPassword':
        return (
          <SetPassword
            email={this.state.email}
            supportEmail={this.props.supportEmail}
            onSuccess={() => {
              window.location.reload()
            }}
          />
        )
      case 'EmailNotFound':
        return (
          <EmailNotFound
            email={this.state.email}
            onGoBack={goBackToPreviousPage}
            onSignUp={() => {
              close()
              $('#sign-up-modal').foundation('open')
            }}
          />
        )
      case 'PendingApproval':
        return <PendingApproval />
    }
  }

  render () {
    return (
      <UserConfirmationModal
        render={this.renderPage}
        initialPage='EnterEmail'
        modalId={this.props.modalId}
        onModalClose={this.handleModalClose}
      />
    )
  }
}

SignInModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired
}

export default SignInModal
